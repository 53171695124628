import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON]: '0x411b0fAcC3489691f28ad58c47006AF5E3Ab3A28',
  [SupportedChainId.DOGECHAIN]: '0xd2480162Aa7F02Ead7BF4C127465446150D58452',
  [SupportedChainId.ZKEVM]: '0x4B9f4d2435Ef65559567e5DbFC1BbB37abC43B57',
};

export const POOL_DEPLOYER_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x2D98E2FA9da15aa6dC9581AB097Ced7af697CB92',
  [SupportedChainId.DOGECHAIN]: '0x56c2162254b0E4417288786eE402c2B41d4e181e',
  [SupportedChainId.ZKEVM]: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
};

export const QUOTER_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON]: '0xa15F0D7377B2A0C0c10db057f641beD21028FC89',
  [SupportedChainId.DOGECHAIN]: '0xd8E1E7009802c914b0d39B31Fc1759A865b727B1',
  [SupportedChainId.ZKEVM]: '0x55BeE1bD3Eb9986f6d2d963278de09eE92a3eF1D',
};

export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON]: '0xf5b509bB0909a69B1c207E495f687a596C168E12',
  [SupportedChainId.DOGECHAIN]: '0x4aE2bD0666c76C7f39311b9B3e39b53C8D7C43Ea',
  [SupportedChainId.ZKEVM]: '0xF6Ad3CcF71Abb3E12beCf6b3D2a74C963859ADCd',
};

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON]: '0x8eF88E4c7CfbbaC1C163f7eddd4B578792201de6',
  [SupportedChainId.DOGECHAIN]: '0x0b012055F770AE7BB7a8303968A7Fb6088A2296e',
  [SupportedChainId.ZKEVM]: '0xd8E1E7009802c914b0d39B31Fc1759A865b727B1',
};

export const MULTICALL_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x6ccb9426CeceE2903FbD97fd833fD1D31c100292',
  [SupportedChainId.DOGECHAIN]: '0x23602819a9E2B1C8eC7605356D5b0F1FBB10ddA5',
  [SupportedChainId.ZKEVM]: '0x61530d6E1c7A47BBB3e48e8b8EdF7569DcFeE121',
};

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON]: '0x157B9913E00204f8c980bb00aa62E22b0dAb1a63',
};

export const FINITE_FARMING_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x9923f42a02A82dA63EE0DbbC5f8E311e3DD8A1f8',
  [SupportedChainId.DOGECHAIN]: '0x481FcFa00Ee6b2384FF0B3c3b5b29aD911c1AAA7',
  [SupportedChainId.ZKEVM]: '0x17bE2Ed4409d8e6c22d46dE599f7C9Af40bD0759',
};

export const INFINITE_FARMING_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0x8a26436e41d0b5fc4C6Ed36C1976fafBe173444E',
  [SupportedChainId.DOGECHAIN]: '0xC712F63E4D57ED1684FB4b428a1DFF10e3338F25',
  [SupportedChainId.ZKEVM]: '0x1fd3f47B363f5b844eD7B7FAB6ceb679A367621E',
};

export const FARMING_CENTER: AddressMap = {
  [SupportedChainId.POLYGON]: '0x7F281A8cdF66eF5e9db8434Ec6D97acc1bc01E78',
  [SupportedChainId.DOGECHAIN]: '0x82831E9565cb574375596eFc090da465283E22A4',
  [SupportedChainId.ZKEVM]: '0x930388c769Da7B4616493d47B5D093D8ec26C969',
}

export const V2_FACTORY_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON]: '0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32'
}

export const V2_ROUTER_ADDRESS: AddressMap = {
  [SupportedChainId.POLYGON]: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff'
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
}

export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.POLYGON]: '0x65770b5283117639760beA3F867b69b3697a91dd'
}