import { Web3Provider } from '@ethersproject/providers'
import { SafeAppConnector } from '@gnosis.pm/safe-apps-web3-react'
import { InjectedConnector } from '@web3-react/injected-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { ALL_SUPPORTED_CHAIN_IDS, SupportedChainId } from '../constants/chains'
import getLibrary from '../utils/getLibrary'
import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'

const WALLETCONNECT_BRIDGE_URL = process.env.REACT_APP_WALLETCONNECT_BRIDGE_URL

const NETWORK_URLS: { [key in SupportedChainId]: string } = {
  // [SupportedChainId.ROPSTEN]: 'https://ropsten.infura.io/v3/dfe214f8d6064d92b2332823213dad94',
  [SupportedChainId.POLYGON]: 'https://rpc-mainnet.maticvigil.com',
  [SupportedChainId.DOGECHAIN]: 'https://dogechain-sj.ankr.com',
  [SupportedChainId.ZKEVM]: 'https://zkevm-rpc.com',
  // [SupportedChainId.BINANCE]: `https://data-seed-prebsc-1-s1.binance.org:8545/`,
}

export const network = new NetworkConnector({
  urls: NETWORK_URLS,
  defaultChainId: 2000,
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? getLibrary(network.provider))
}

export const injected = new InjectedConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
})

export const gnosisSafe = new SafeAppConnector()

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
  rpc: NETWORK_URLS,
  bridge: WALLETCONNECT_BRIDGE_URL,
  qrcode: true,
  pollingInterval: 15000,
})