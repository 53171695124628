import { useEffect } from 'react'
import { InfiniteFarmingsList } from '../../components/InfiniteFarmingsList'
import { useInfoSubgraph } from '../../hooks/subgraph/useInfoSubgraph'
import styled from 'styled-components/macro'
import { useInfiniteFarming } from '../../hooks/useInfiniteFarming'
import { useLimitFarming } from '../../hooks/useLimitFarming'

const Wrapper = styled.div`
  max-width: 95vw;
  min-width: 95vw;
`

const Title = styled.div`
  font-size: 21px;
  margin-bottom: 1rem;
`

export default function InfiniteEvents() {
  const {
    fetchLimitFarmings: { limitFarmings, limitFarmingsLoading, fetchLimitFarmingsFn },
  } = useInfoSubgraph()

  const { detachFarming, changeRate, addRewards, attachFarming, claimRewards } = useLimitFarming()

  useEffect(() => {
    fetchLimitFarmingsFn()
  }, [])

  return (
    <Wrapper>
      <Title>Active Limit Farmings</Title>
      {limitFarmings && (
        <InfiniteFarmingsList
          list={[...limitFarmings.futureEvents, ...limitFarmings.currentEvents]}
          loading={limitFarmingsLoading}
          detachCallback={detachFarming}
          attachCallback={attachFarming}
          changeRateCallback={changeRate}
          addRewardsCallback={addRewards}
          claimRewards={claimRewards}
          type={'limit'}
          items={6}
        ></InfiniteFarmingsList>
      )}
    </Wrapper>
  )
}
