import styled from 'styled-components/macro'
import copy from 'copy-to-clipboard'
import { useMemo, useState } from 'react'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { useMutation } from '@apollo/client'

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`

const ListHeader = styled.div<{ items: number }>`
  display: flex;

  & > span {
    min-width: ${({ items }) => `calc(100%/${items})`};
  }
`

const ListItem = styled.li<{ isLimit: boolean }>`
  padding: 2rem 0;

  & > span {
    display: inline-block;
    min-width: calc(100% / ${({ isLimit }) => (isLimit ? 6 : 10)});
  }
`
const Button = styled.button`
  border: none;
  border-radius: 6px;
  color: white;
  padding: 6px 8px;
`

const DetachButton = styled(Button)`
  background-color: red;
`
const AddRewardsButton = styled(Button)`
  background-color: #36f;
`

const CopyButton = styled(Button)`
  background-color: #36f;
  margin-left: 1rem;
`

const EditButton = styled(Button)`
  background-color: #36f;
  margin-left: 1rem;
`

const EditInput = styled.input`
  max-width: 100px;
`

function trim(str: string) {
  if (str.length <= 5) return str

  return str.slice(0, 5) + '...'
}

function InfiniteFarmingItem({
  item: {
    id,
    pool,
    reward,
    bonusReward,
    rewardToken,
    bonusRewardToken,
    rewardRate,
    bonusRewardRate,
    enterStartTime,
    startTime,
    endTime,
    isDetached,
    rewardLeftFor,
    bonusRewardLeftFor,
    minRangeLength
  },
  detachCallback,
  attachCallback,
  changeRateCallback,
  addRewardsCallback,
  claimRewards,
  type,
}: {
  item: any
  detachCallback: any
  attachCallback: any
  changeRateCallback: any
  addRewardsCallback: any
  claimRewards: any
  type: any
}) {
  const [rewardEditToggled, setRewardRateToggled] = useState(false)
  const [bonusRewardEditToggled, setBonusRewardRateToggled] = useState(false)
  const [addRewardsToggled, setAddRewardsToggled] = useState(false)
  const [addBonusRewardsToggled, setAddBonusRewardsToggled] = useState(false)
  const [removeRewardsToggled, setRemoveRewardsToggled] = useState(false)
  const [removeBonusRewardsToggled, setRemoveBonusRewardsToggled] = useState(false)

  const [_rewardRate, setRewardRate] = useState('')
  const [_bonusRewardRate, setBonusRewardRate] = useState('')
  const [_rewardAmount, setRewardAmount] = useState('')
  const [_bonusRewardAmount, setBonusRewardAmount] = useState('')
  const [_removedRewardAmount, setRemovedRewardAmount] = useState('')
  const [_removedBonusRewardAmount, setRemovedBonusRewardAmount] = useState('')
  const [rewardAmount, setReward] = useState('0')
  const [bonusRewardAmount, setBonus] = useState('0')

  const isLimit = useMemo(() => {
    return type === 'limit'
  }, [type])

  return (
    <ListItem isLimit={type === 'limit'}>
      <span>
        <span>{trim(id)}</span>
        <CopyButton onClick={() => copy(id)}>Copy</CopyButton>
      </span>
      <span>
        <span>{trim(pool.id || pool)}</span>
        <CopyButton onClick={() => copy(pool)}>Copy</CopyButton>
      </span>
      <span>
        {!addRewardsToggled && !removeRewardsToggled ? (
          <>
            <span>{`${+reward / Math.pow(10, +rewardToken.decimals)} ${rewardToken.symbol}`}</span>
            <EditButton onClick={() => setAddRewardsToggled(!addRewardsToggled)}>Add</EditButton>
            {isLimit && <EditButton onClick={() => setRemoveRewardsToggled(!removeRewardsToggled)}>Remove</EditButton>}
          </>
        ) : (
          <>
            <EditInput
              autoFocus
              onChange={(v) =>
                addRewardsToggled ? setRewardAmount(v.target.value) : setRemovedRewardAmount(v.target.value)
              }
              value={addRewardsToggled ? _rewardAmount : _removedRewardAmount}
            ></EditInput>
            <EditButton
              onClick={() => {
                if (addRewardsToggled) {
                  addRewardsCallback(
                    rewardToken.id,
                    bonusRewardToken.id,
                    pool.id || pool,
                    startTime,
                    endTime,
                    reward,
                    bonusReward,
                    parseUnits(_rewardAmount, rewardToken.decimals),
                    'main'
                  )
                } else {
                  claimRewards(
                    rewardToken.id,
                    bonusRewardToken.id,
                    pool.id,
                    startTime,
                    endTime,
                    parseUnits(_removedRewardAmount, rewardToken.decimals).toString(),
                    0
                  )
                }
                setAddRewardsToggled(false)
                setRemoveRewardsToggled(false)
              }}
            >
              Save
            </EditButton>
            <EditButton
              onClick={() => {
                setAddRewardsToggled(false)
                setRemoveRewardsToggled(false)
              }}
            >
              X
            </EditButton>
          </>
        )}
      </span>
      {!isLimit && (
        <>
          <span>
            {!rewardEditToggled ? (
              <>
                <span>{rewardRate ? rewardRate / Math.pow(10, rewardToken.decimals) : 0}</span>
                <EditButton onClick={() => setRewardRateToggled(!rewardEditToggled)}>Edit</EditButton>{' '}
              </>
            ) : (
              <>
                <EditInput autoFocus onChange={(v) => setRewardRate(v.target.value)} value={_rewardRate}></EditInput>
                <EditButton
                  onClick={() => {
                    changeRateCallback(
                      rewardToken.id,
                      bonusRewardToken.id,
                      pool.id || pool,
                      startTime,
                      endTime,
                      rewardRate,
                      bonusRewardRate,
                      parseUnits(_rewardRate, rewardToken.decimals),
                      'main'
                    )
                    setRewardRateToggled(!rewardEditToggled)
                  }}
                >
                  Save
                </EditButton>{' '}
                <EditButton onClick={() => setRewardRateToggled(!rewardEditToggled)}>X</EditButton>
              </>
            )}
          </span>
          <span>{rewardLeftFor + ' min'}</span>
        </>
      )}
      <span>
        {!addBonusRewardsToggled && !removeBonusRewardsToggled ? (
          <>
            <span>{`${bonusReward / Math.pow(10, bonusRewardToken.decimals)} ${bonusRewardToken.symbol}`}</span>
            <EditButton onClick={() => setAddBonusRewardsToggled(!addBonusRewardsToggled)}>Add</EditButton>
            {isLimit && (
              <EditButton onClick={() => setRemoveBonusRewardsToggled(!removeBonusRewardsToggled)}>Remove</EditButton>
            )}
          </>
        ) : (
          <>
            <EditInput
              autoFocus
              onChange={(v) =>
                addBonusRewardsToggled
                  ? setBonusRewardAmount(v.target.value)
                  : setRemovedBonusRewardAmount(v.target.value)
              }
              value={addBonusRewardsToggled ? _bonusRewardAmount : _removedBonusRewardAmount}
            ></EditInput>
            <EditButton
              onClick={() => {
                if (addBonusRewardsToggled) {
                  addRewardsCallback(
                    rewardToken.id,
                    bonusRewardToken.id,
                    pool.id || pool,
                    startTime,
                    endTime,
                    reward,
                    bonusReward,
                    parseUnits(_bonusRewardAmount, bonusRewardToken.decimals),
                    'bonus'
                  )
                } else {
                  claimRewards(
                    rewardToken.id,
                    bonusRewardToken.id,
                    pool.id,
                    startTime,
                    endTime,
                    0,
                    parseUnits(_removedBonusRewardAmount, bonusRewardToken.decimals).toString()
                  )
                }
                setAddBonusRewardsToggled(false)
                setRemoveBonusRewardsToggled(false)
              }}
            >
              Save
            </EditButton>
            <EditButton
              onClick={() => {
                setAddBonusRewardsToggled(false)
                setRemoveBonusRewardsToggled(false)
              }}
            >
              X
            </EditButton>
          </>
        )}
      </span>
      {!isLimit && (
        <>
          <span>
            {!bonusRewardEditToggled ? (
              <>
                <span>{bonusRewardRate ? bonusRewardRate / Math.pow(10, bonusRewardToken.decimals) : 0}</span>
                <EditButton onClick={() => setBonusRewardRateToggled(!bonusRewardEditToggled)}>Edit</EditButton>{' '}
              </>
            ) : (
              <>
                <EditInput
                  autoFocus
                  onChange={(v) => setBonusRewardRate(v.target.value)}
                  value={_bonusRewardRate}
                ></EditInput>
                <EditButton
                  onClick={() => {
                    changeRateCallback(
                      rewardToken.id,
                      bonusRewardToken.id,
                      pool.id || pool,
                      startTime,
                      endTime,
                      rewardRate,
                      bonusRewardRate,
                      parseUnits(_bonusRewardRate, bonusRewardToken.decimals),
                      'bonus'
                    )
                    setBonusRewardRateToggled(!bonusRewardEditToggled)
                  }}
                >
                  Save
                </EditButton>{' '}
                <EditButton onClick={() => setBonusRewardRateToggled(!bonusRewardEditToggled)}>X</EditButton>
              </>
            )}
          </span>
          <span style={{ fontSize: '15px' }}>{bonusRewardLeftFor + ' min'}</span>
        </>
      )}
      <span>{minRangeLength || '0'}</span>
      <span>
        {!isDetached ? (
          <DetachButton onClick={() => detachCallback(rewardToken.id, bonusRewardToken.id, pool, startTime, endTime)}>
            Detach
          </DetachButton>
        ) : (
          <DetachButton onClick={() => attachCallback(rewardToken.id, bonusRewardToken.id, pool, startTime, endTime)}>
            Attach
          </DetachButton>
        )}
      </span>
    </ListItem>
  )
}

export function InfiniteFarmingsList({
  list,
  loading,
  detachCallback,
  attachCallback,
  changeRateCallback,
  addRewardsCallback,
  claimRewards,
  type,
  items,
}: {
  list: any[]
  loading: boolean
  detachCallback: any
  attachCallback: any
  changeRateCallback: any
  addRewardsCallback: any
  claimRewards: any
  type: any
  items: number
}) {
  const isLimit = useMemo(() => {
    return type === 'limit'
  }, [type])

  return !list || loading ? (
    <span>Loading</span>
  ) : (
    <>
      <ListHeader items={items}>
        <span>ID</span>
        <span>Pool</span>
        <span>Reward</span>
        {!isLimit && (
          <>
            <span>Reward Rate</span>
            <span>Left for</span>
          </>
        )}
        <span>Bonus Reward</span>
        {!isLimit && (
          <>
            <span>Bonus Reward Rate</span>
            <span>Left for</span>
          </>
        )}
        <span>Min range</span>
        <span>Actions</span>
      </ListHeader>
      <List>
        {list.map((item, i) => (
          <InfiniteFarmingItem
            key={i}
            item={item}
            attachCallback={attachCallback}
            detachCallback={detachCallback}
            changeRateCallback={changeRateCallback}
            addRewardsCallback={addRewardsCallback}
            claimRewards={claimRewards}
            type={type}
          />
        ))}
      </List>
    </>
  )
}
