import { Trans } from '@lingui/macro'
import { Currency } from '@uniswap/v3-core'
import { computePoolAddress } from '../../hooks/computePoolAddress'
import { useActiveWeb3React } from '../../hooks/web3'
import { POOL_DEPLOYER_ADDRESS, FINITE_FARMING_ADDRESS } from '../../constants/addresses'
import { useStakerIncentives } from '../../hooks/useStakerIncentives'
// import { BigNumber, FixedNumber } from 'ethers'
import { useAllTransactions } from '../../state/transactions/hooks'
import { useEffect, useMemo, useState } from 'react'
import Loader from '../../components/Loader'
import { useHistory } from 'react-router-dom'
import { parseUnits } from 'ethers/lib/utils'
import Modal from '../../components/Modal'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styled from 'styled-components/macro'

const Link = styled.a`
padding: 8px; 16px;
border-radius: 8px;
width: 100%;
display: flex;
justify-content: center;
text-decoration: none;

&:not(:last-of-type) {
  margin-right: 1rem;
}
`

const GnosisLink = styled(Link)`
  background-color: #36f;
  border: 1px solid #36f;
  color: white;
`

const ContractLink = styled(Link)`
  background-color: white;
  border: 1px solid white;
  color: #36f;
`

export interface Tiers {
  low: string
  medium: string
  high: string
}

export function CreateIncentive({
  currencyA,
  currencyB,
  tokenRewardAddress,
  reward,
  bonusRewardAddress,
  bonusReward,
  tierLimits,
  lockedToken,
  low,
  tierMultipliers,
  startTime,
  endTime,
  toggleDisabled,
  enterStartTime,
  isTierFarming,
  minimalRange
}: {
  currencyA: Currency
  currencyB: Currency
  tokenRewardAddress: Currency
  reward: string
  bonusRewardAddress: Currency
  bonusReward: string
  tierLimits: Tiers
  low: any
  tierMultipliers: Tiers
  startTime: number
  lockedToken: Currency
  endTime: number
  toggleDisabled: () => any
  enterStartTime: number
  isTierFarming: boolean
  minimalRange: string
}) {
  const { account, chainId } = useActiveWeb3React()

  const poolDeployerAddress = chainId && POOL_DEPLOYER_ADDRESS[chainId]

  const poolAddress = computePoolAddress({
    poolDeployer: poolDeployerAddress,
    tokenA: currencyA.wrapped,
    tokenB: currencyB.wrapped,
  })

  const _reward = parseUnits(reward.toString(), tokenRewardAddress.wrapped.decimals).toString()
  const _bonusReward = parseUnits(bonusReward.toString(), bonusRewardAddress.wrapped.decimals).toString()

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs
      .filter((tx) => new Date().getTime() - tx.addedTime < 86_400_000)
      .sort((a, b) => b.addedTime - a.addedTime)
  }, [allTransactions])

  const confirmed = useMemo(
    () => sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash),
    [sortedRecentTransactions, allTransactions]
  )

  const { createIncentiveTx, createdHash } = useStakerIncentives({
    low,
    rewardAddress: tokenRewardAddress.wrapped.address,
    bonusRewardAddress: bonusRewardAddress.wrapped.address,
    poolAddress,
    startTime,
    endTime,
    lockedToken,
    tierLimits,
    tierMultipliers,
    reward: _reward,
    bonusReward: _bonusReward,
    enterStartTime,
    isTierFarming,
    minimalRange
  })

  const [createModal, setCreateModal] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (!createdHash) return

    if (createdHash === 'failed') {
      toggleDisabled()
    } else if (confirmed?.includes(createdHash)) {
      history.push('/farming/future-events')
    }
  }, [createdHash, confirmed])

  return (
    <>
      <Modal isOpen={createModal} onDismiss={() => setCreateModal(false)}>
        <div style={{ padding: '2rem', width: '100%' }}>
          <div style={{ marginBottom: '1rem', fontSize: '18px', fontWeight: 600 }}>Create incentive</div>
          <div style={{ marginBottom: '1rem' }}>Incentive key</div>
          <div style={{ display: 'flex', marginBottom: '1rem' }}>
            <input
              style={{ padding: '8px', width: '100%', borderRadius: '8px', border: 'none', marginRight: '1rem' }}
              onChange={(e) => e.preventDefault()}
              value={[
                tokenRewardAddress.wrapped.address,
                bonusRewardAddress.wrapped.address,
                poolAddress,
                startTime,
                endTime,
                account,
              ]}
            />
            <CopyToClipboard
              text={`["${tokenRewardAddress.wrapped.address}", "${bonusRewardAddress.wrapped.address}", "${poolAddress}", ${startTime}, ${endTime}, "${account}"]`}
            >
              <button>Copy</button>
            </CopyToClipboard>
          </div>
          <div style={{ marginBottom: '1rem' }}>Reward</div>
          <div style={{ display: 'flex', marginBottom: '1rem' }}>
            <input
              style={{ padding: '8px', width: '100%', borderRadius: '8px', border: 'none', marginRight: '1rem' }}
              onChange={(e) => e.preventDefault()}
              value={_reward}
            />
            <CopyToClipboard text={_reward}>
              <button>Copy</button>
            </CopyToClipboard>
          </div>
          <div style={{ marginBottom: '1rem' }}>Bonus reward</div>
          <div style={{ display: 'flex', marginBottom: '1rem' }}>
            <input
              style={{ padding: '8px', width: '100%', borderRadius: '8px', border: 'none', marginRight: '1rem' }}
              onChange={(e) => e.preventDefault()}
              value={_bonusReward}
            />
            <CopyToClipboard text={_bonusReward}>
              <button>Copy</button>
            </CopyToClipboard>
          </div>
          <div style={{ display: 'flex', marginTop: '2rem' }}>
            <GnosisLink href={'https://gnosis.io'} target="_blank">
              Open Gnosis
            </GnosisLink>
            <ContractLink
              href={`https://polygonscan.com/address/${FINITE_FARMING_ADDRESS[chainId]}#writeContract`}
              target="_blank"
            >
              Open contract
            </ContractLink>
          </div>
        </div>
      </Modal>
      <button
        onClick={() => {
          // setCreateModal(true)
          if (!createdHash || createdHash === 'failed') {
            createIncentiveTx()
            toggleDisabled()
          }
        }}
        style={{
          width: '100%',
          height: '2.8rem',
          background: '#601FB3',
          color: 'white',
          border: 'none',
          borderRadius: '10px',
          fontSize: '16px',
          fontFamily: 'Inter',
        }}
      >
        {createdHash && confirmed.includes(createdHash) ? (
          <Trans>Created!</Trans>
        ) : createdHash && !confirmed.includes(createdHash) && createdHash !== 'failed' ? (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loader
              style={{
                display: 'block',
              }}
              stroke={'white'}
            />
            <span
              style={{
                marginLeft: '10px',
              }}
            >
              Creating
            </span>
          </span>
        ) : (
          <Trans>Create incentive</Trans>
        )}
      </button>
    </>
  )
}
