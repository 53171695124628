import styled from 'styled-components/macro'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import { TYPE } from 'theme'
import { Trans } from '@lingui/macro'
import { RouteComponentProps } from 'react-router-dom'
import { CurrencyDropdown } from './styled'
import { useCallback, useEffect, useState } from 'react'
import { useActiveWeb3React } from '../../hooks/web3'
import { Currency } from '@uniswap/sdk-core'
import { currencyId } from '../../utils/currencyId'
import { WETH9_EXTENDED } from '../../constants/tokens'
import { useV3MintState, useV3MintActionHandlers, useV3DerivedMintInfo } from 'state/mint/v3/hooks'
import { useCurrency } from '../../hooks/Tokens'
import { Field } from '../../state/mint/v3/actions'
import { useIncentiveHandlers } from '../../state/staking/hooks'
import { CreateIncentive } from './CreateIncentive'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { FINITE_FARMING_ADDRESS } from '../../constants/addresses'
import { stringToColour } from '../../utils/stringToColour'
import Loader from '../../components/Loader'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useMemo } from 'react'
import Toggle from '../../components/Toggle'

const PageWrapper = styled(AutoColumn)`
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`

const MainContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 100%;
  `}
`

const TokenLogo = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 1rem;
`

const Input = styled.input`
  margin-right: 1rem;
  background-color: #c4c4c445;
  border: none;
  border-radius: 16px;
  color: white;
  font-size: 16px;
  padding-left: 1rem;
  margin-top: 1rem;
  width: 50%;
  margin-top: 0;
  height: 40px;
`

const TimeInput = styled(Input)`
  padding-left: 10px;
  margin-right: 7px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100% !important;

  &:last-of-type {
    margin-right: 0;
  }
  `}
`

const PairWrapper = styled.div`
  display: flex;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    `}
`

const PairToken = styled.div`
  width: 50%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 1rem;
    width: 100%;
  `}
`

const PairSelectorWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  padding-right: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    padding-right: unset;
    & > ${CurrencyDropdown} {
      width: 100%;
      &:first-of-type {
        margin-bottom: 1rem;
      }
    }
  `}
`

const RewardToken = styled.div`
  width: 50%;
  opacity: ${({ active }) => (active ? '1' : '0.4')};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
`

const RewardInput = styled.div`
  display: flex;
  margin-top: 1rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
      flex-direction: column;

      & > ${Input} {
        width: 100%;
        margin-bottom: 1rem;
      }

      & > ${CurrencyDropdown} {
        width: 100%;
      }
  `}
`

const TimeWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: column;
      `}
`

const TimeWrapperPart = styled.div`
  width: 50%;

  &:first-of-type {
    margin-right: 0.5rem;
  }

  &:last-of-type {
    margin-left: 0.5rem;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 100%;

      &:last-of-type {
        margin-left: 0;
        margin-top: 1rem;
      }
  `}
`

const TimeWrapperPartButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  `}
`

const TimeWrapperControls = styled.div`
  display: flex;

  & > button {
    &:hover {
      text-decoration: underline;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin: 1rem 0;
  `}
`

const TimeInputsWrapper = styled.div`
  padding: 1rem;
  display: flex;
  background-color: rgba(132, 132, 132, 0.14);
  border: 1px solid ${({ error }) => (error ? 'red' : 'rgba(132, 132, 132, 0.14)')};
  border-radius: 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `}
`

const TimeInputsWrapperInner = styled.div`
  margin-right: 1rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-right: 0;
  margin-bottom: 1rem;
  `}
`

const TimeInputTitle = styled.div`
  margin-bottom: 1rem;
`

const SummaryWrapper = styled.div`
  background-color: rgba(132, 132, 132, 0.14);
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 1rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  align-items: unset;
`}
`

const SummaryPool = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  min-width: 150px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-bottom: 1rem;
`}
`

const SummaryDate = styled.div`
  margin-right: 2rem;
  min-width: 155px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-bottom: 1rem;
`}
`

const ErrorWrapper = styled.div`
  padding: 8px;
  border-radius: 8px;
  background-color: #6d0a0a7d;
  color: #e82247;
  border: 1px solid #e20f55;
  text-align: center;
  margin-top: 1rem;
`

export function NewIncentivePage({
  match: {
    params: { currencyIdA, currencyIdB, rewardTokenId, bonusRewardTokenId, lockedTokenId },
  },
  history,
}: RouteComponentProps<{
  currencyIdA?: string
  currencyIdB?: string
  rewardTokenId?: string
  bonusRewardTokenId?: string
  lockedTokenId?: string
}>) {
  const { chainId, account } = useActiveWeb3React()

  // check for existing position if tokenId in url
  // fee selection from url
  // const feeAmount: FeeAmount | undefined =
  //   feeAmountFromUrl && Object.values(FeeAmount).includes(parseFloat(feeAmountFromUrl))
  //     ? parseFloat(feeAmountFromUrl)
  //     : undefined

  const [reward, setReward] = useState('')
  const [bonusReward, setBonusReward] = useState('')
  const [validAddress, setValidAddress] = useState(false)

  const [lowTierLimit, setLowTierLimit] = useState('')
  const [mediumTierLimit, setMediumTierLimit] = useState('')
  const [highTierLimit, setHighTierLimit] = useState('')

  const [lowTierMultiplier, setLowTierMultiplier] = useState('')
  const [mediumTierMultiplier, setMediumTierMultiplier] = useState('')
  const [highTierMultiplier, setHighTierMultiplier] = useState('')

  const [minimalRange, setMinimalRange] = useState('')

  const feeAmount = 500

  const baseCurrency = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)

  const tokenCurrency = useCurrency(rewardTokenId)
  const bonusRewardCurrency = useCurrency(bonusRewardTokenId)

  const lockedCurrency = useCurrency(lockedTokenId)

  // prevent an error if they input ETH/WETH
  //TODO
  const quoteCurrency =
    baseCurrency && currencyB && baseCurrency.wrapped.equals(currencyB.wrapped) ? undefined : currencyB

  // mint state
  const { independentField, typedValue, startPriceTypedValue } = useV3MintState()

  const { dependentField, parsedAmounts, noLiquidity, currencies, errorCode } = useV3DerivedMintInfo(
    baseCurrency ?? undefined,
    quoteCurrency ?? undefined,
    feeAmount,
    baseCurrency ?? undefined,
    undefined,
    tokenCurrency ?? undefined,
    reward,
    bonusRewardCurrency ?? undefined,
    bonusReward
  )

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  const {
    onFieldAInput,
    onFieldBInput,
    onRewardTokenInput,
    onBonusRewardTokenInput,
    onLeftRangeInput,
    onRightRangeInput,
    onStartPriceInput,
  } = useV3MintActionHandlers(noLiquidity)

  const { onRefundeeInput, onRewardInput, onBonusRewardInput, onTimeInput } = useIncentiveHandlers()

  const toggleWalletModal = useWalletModalToggle()

  const baseDateState = {
    enter: '',
    start: '',
    end: '',
  }

  const [approvalReward, approveRewardCallback] = useApproveCallback(
    parsedAmounts[Field.REWARD_TOKEN],
    chainId ? FINITE_FARMING_ADDRESS[chainId] : undefined
  )

  const [approvalBonusReward, approveBonusRewardCallback] = useApproveCallback(
    parsedAmounts[Field.BONUS_REWARD_TOKEN],
    chainId ? FINITE_FARMING_ADDRESS[chainId] : undefined
  )

  const showRewardApproval = approvalReward !== ApprovalState.APPROVED && !!parsedAmounts[Field.BONUS_REWARD_TOKEN]
  const showBonusRewardApproval =
    approvalBonusReward !== ApprovalState.APPROVED && !!parsedAmounts[Field.BONUS_REWARD_TOKEN]

  const [year, setYear] = useState({ ...baseDateState })
  const [month, setMonth] = useState({ ...baseDateState })
  const [day, setDay] = useState({ ...baseDateState })
  const [hour, setHour] = useState({ ...baseDateState })
  const [minute, setMinute] = useState({ ...baseDateState })
  const [second, setSecond] = useState({ enter: '0', start: '0', end: '0' })
  const [showErrors, setShowErrors] = useState(null)

  useEffect(() => {
    if (!errorCode) {
      setShowErrors(null)
    }
  }, [errorCode])

  function checkWeek(day: number, month: number, year: number) {
    const months30 = [4, 6, 9, 11]
    const months31 = [1, 3, 5, 7, 8, 10, 12]

    let leap = true

    if (year % 4 !== 0 && year % 100 === 0 && year % 400 !== 0) {
      leap = false
    }

    if (months30.includes(month) && day + 7 > 30) {
      return day + 7 - 30
    }

    if (months31.includes(month) && day + 7 > 31) {
      return day + 7 - 31
    }

    if (month === 2 && leap && day + 7 > 29) {
      return day + 7 - 29
    }

    if (month === 2 && !leap && day + 7 > 28) {
      return day + 7 - 28
    }

    return day + 7
  }

  function checkMonth(day: number, month: number, year: number) {
    const months30 = [4, 6, 9, 11]
    const months31 = [1, 3, 5, 7, 8, 10, 12]

    let leap = true

    if (day < 0 || day > 31) {
      return false
    }

    if (year % 4 !== 0 && year % 100 === 0 && year % 400 !== 0) {
      leap = false
    }

    if (months30.includes(month) && day <= 30) {
      return true
    }

    if (months31.includes(month) && day <= 31) {
      return true
    }

    if (month === 2) {
      if (leap && day <= 29) {
        return true
      }

      if (!leap && day <= 28) {
        return true
      }
    }

    return false
  }

  function handleDateSelect(e: KeyboardEvent, key: string) {
    const target = e.target as HTMLInputElement

    if (e.key === '8' || target.value === '') {
      switch (target.id) {
        case `year-${key}`:
          setYear({
            ...year,
            [key]: year[key].slice(0, -1),
          })
          break
        case `month-${key}`:
          setMonth({
            ...month,
            [key]: month[key].slice(0, -1),
          })
          break
        case `day-${key}`:
          setDay({
            ...day,
            [key]: day[key].slice(0, -1),
          })
          break
        case `hour-${key}`:
          setHour({
            ...hour,
            [key]: hour[key].slice(0, -1),
          })
          break
        case `minute-${key}`:
          setMinute({
            ...minute,
            [key]: minute[key].slice(0, -1),
          })
          break
      }
      return
    }

    if (!Number.isInteger(+target.value)) {
      return
    }

    if (target.value.length === target.maxLength && target.validity.patternMismatch) {
      return
    }

    const yearEl = document.getElementById(`year-${key}`) as HTMLInputElement
    const monthEl = document.getElementById(`month-${key}`) as HTMLInputElement
    const dayEl = document.getElementById(`day-${key}`) as HTMLInputElement
    const hourEl = document.getElementById(`hour-${key}`) as HTMLInputElement
    const minuteEl = document.getElementById(`minute-${key}`) as HTMLInputElement

    const val = Number.parseInt(target.value, 10)
    const valStr = target.value

    switch (target.id) {
      case `year-${key}`:
        setYear({
          ...year,
          [key]: valStr,
        })
        break
      case `month-${key}`:
        if (!yearEl.value) {
          yearEl.focus()
        } else if (val >= 0 && val <= 12) {
          setMonth({
            ...month,
            [key]: valStr,
          })
        }
        break
      case `day-${key}`:
        if (!yearEl.value) {
          yearEl.focus()
        } else if (!monthEl.value) {
          monthEl.focus()
        } else if (checkMonth(val, +monthEl.value, +yearEl.value)) {
          setDay({
            ...day,
            [key]: valStr,
          })
        }
        break
      case `hour-${key}`:
        setHour({
          ...hour,
          [key]: valStr,
        })
        break
      case `minute-${key}`:
        setMinute({
          ...minute,
          [key]: valStr,
        })
        break
    }

    if (target.maxLength === target.value.length) {
      switch (target.id) {
        case `year-${key}`:
          monthEl.focus()
          break
        case `month-${key}`:
          dayEl.focus()
          break
        case `day-${key}`:
          hourEl.focus()
          break
        case `hour-${key}`:
          minuteEl.focus()
          break
        case `minute-${key}`:
          break
      }
    }
  }

  const handleRewardTokenSelect = useCallback(
    (token: Currency) => {
      const tokenNew = currencyId(token, chainId)
      history.push(`/farming/create-event/${currencyIdA}/${currencyIdB}/${tokenNew}`)
    },
    [currencyIdA, currencyIdB, chainId]
  )

  const handleBonusRewardTokenSelect = useCallback(
    (token: Currency) => {
      const bonusReward = currencyId(token, chainId)
      history.push(`/farming/create-event/${currencyIdA}/${currencyIdB}/${rewardTokenId}/${bonusReward}`)
    },
    [currencyIdA, currencyIdB, rewardTokenId, chainId]
  )

  const handleLockedTokenSelect = useCallback(
    (token: Currency) => {
      const lockedToken = currencyId(token, chainId)
      history.push(
        `/farming/create-event/${currencyIdA}/${currencyIdB}/${rewardTokenId}/${bonusRewardTokenId}/${lockedToken}`
      )
    },
    [currencyIdA, currencyIdB, rewardTokenId, bonusRewardTokenId, chainId]
  )

  const handleCurrencySelect = useCallback(
    (currencyNew: Currency, currencyIdOther?: string): (string | undefined)[] => {
      const currencyIdNew = currencyId(currencyNew, chainId)

      let chainSymbol

      if (chainId === 137) {
        chainSymbol = 'MATIC'
      }

      if (currencyIdNew === currencyIdOther) {
        // not ideal, but for now clobber the other if the currency ids are equal
        return [currencyIdNew, undefined]
      } else {
        // prevent weth + eth
        const isETHOrWETHNew =
          currencyIdNew === chainSymbol || (chainId !== undefined && currencyIdNew === WETH9_EXTENDED[chainId]?.address)
        const isETHOrWETHOther =
          currencyIdOther !== undefined &&
          (currencyIdOther === chainSymbol ||
            (chainId !== undefined && currencyIdOther === WETH9_EXTENDED[chainId]?.address))

        if (isETHOrWETHNew && isETHOrWETHOther) {
          return [currencyIdNew, undefined]
        } else {
          return [currencyIdNew, currencyIdOther]
        }
      }
    },
    [chainId]
  )

  const handleCurrencyASelect = useCallback(
    (currencyANew: Currency) => {
      const [idA, idB] = handleCurrencySelect(currencyANew, currencyIdB)
      if (idB === undefined) {
        history.push(`/farming/create-event/${idA}${rewardTokenId ? '/' + rewardTokenId : ''}`)
      } else {
        history.push(`/farming/create-event/${idA}/${idB}${rewardTokenId ? '/' + rewardTokenId : ''}`)
      }
    },
    [handleCurrencySelect, currencyIdB, history]
  )

  const handleCurrencyBSelect = useCallback(
    (currencyBNew: Currency) => {
      const [idB, idA] = handleCurrencySelect(currencyBNew, currencyIdA)
      if (idA === undefined) {
        history.push(`/farming/create-event/${idB}${rewardTokenId ? '/' + rewardTokenId : ''}`)
      } else {
        history.push(`/farming/create-event/${idA}/${idB}${rewardTokenId ? '/' + rewardTokenId : ''}`)
      }
    },
    [handleCurrencySelect, currencyIdA, history]
  )

  const handleRewardInput = useCallback((value: string) => {
    if (errorCode) {
      setShowErrors(errorCode)
    }
    if ((+value >= 0 && /^\d*\.?\d*$/.test(value)) || value === '') {
      setReward(value)
      onRewardInput(value)
    }
  }, [])

  const handleBonusRewardInput = useCallback((value: string) => {
    if (errorCode) {
      setShowErrors(errorCode)
    }
    if ((+value >= 0 && /^\d*\.?\d*$/.test(value)) || value === '') {
      setBonusReward(value)
      onBonusRewardInput(value)
    }
  }, [])

  function errored(...args) {
    return args.includes(errorCode) && showErrors
  }

  function setStart(key: string) {
    setYear({
      ...year,
      [key]: year.start,
    })
    setMonth({
      ...month,
      [key]: month.start,
    })
    setDay({
      ...day,
      [key]: day.start,
    })
    setHour({
      ...hour,
      [key]: hour.start,
    })
    setMinute({
      ...minute,
      [key]: minute.start,
    })
    setSecond({
      ...second,
      [key]: second.start,
    })
  }

  function setNow(key: string, add = 0) {
    const date = new Date(new Date().getTime() + add * 60000)
    const _year = date.getFullYear()
    const _month = date.getMonth() + 1
    const _day = date.getDate()
    const _hour = date.getHours()
    const _minute = date.getMinutes()

    setYear({
      ...year,
      [key]: _year.toString(),
    })

    setMonth({
      ...month,
      [key]: _month.toString(),
    })

    setDay({
      ...day,
      [key]: _day.toString(),
    })

    setHour({
      ...hour,
      [key]: _hour.toString(),
    })

    setMinute({
      ...minute,
      [key]: _minute.toString(),
    })

    setSecond({
      ...second,
      [key]: '0',
    })
  }

  function setDayAfter(key: string) {
    if (!checkMonth(+day.start + 1, +month.start, +year.start)) {
      setDay({
        ...day,
        [key]: '1',
      })
      if (+month.start !== 12) {
        setMonth({
          ...month,
          [key]: `${+month.start + 1}`,
        })
        setYear({
          ...year,
          [key]: `${+year.start}`,
        })
      } else {
        setMonth({
          ...month,
          [key]: '1',
        })
        setYear({
          ...year,
          [key]: `${+year.start + 1}`,
        })
      }
    } else {
      setYear({
        ...year,
        [key]: `${+year.start}`,
      })
      setMonth({
        ...month,
        [key]: `${+month.start}`,
      })
      setDay({
        ...day,
        [key]: `${+day.start + 1}`,
      })
    }

    setHour({
      ...hour,
      [key]: hour.start,
    })
    setMinute({
      ...minute,
      [key]: minute.start,
    })
    setSecond({
      ...second,
      [key]: second.start,
    })
  }

  function setWeekAfter(key: string) {
    const newDay = checkWeek(+day.start, +month.start, +year.start)

    if (newDay < +day.start) {
      if (+month.start !== 12) {
        setMonth({
          ...month,
          [key]: `${+month.start + 1}`,
        })

        setDay({
          ...day,
          [key]: `${newDay}`,
        })
      } else {
        setYear({
          ...year,
          [key]: `${+year.start + 1}`,
        })
        setMonth({
          ...month,
          [key]: '1',
        })

        setDay({
          ...day,
          [key]: `${newDay}`,
        })
      }
    } else {
      setDay({
        ...day,
        [key]: `${newDay}`,
      })
      setMonth({
        ...month,
        [key]: month.start,
      })
      setYear({
        ...year,
        [key]: year.start,
      })
    }

    setHour({
      ...hour,
      [key]: hour.start,
    })
    setMinute({
      ...minute,
      [key]: minute.start,
    })
    setSecond({
      ...second,
      [key]: second.start,
    })
  }

  function setMonthAfter(key: string) {
    let leap = true

    if (+year.start % 4 !== 0 && +year.start % 100 === 0 && +year.start % 400 !== 0) {
      leap = false
    }

    if (+month.start === 12) {
      setYear({
        ...year,
        [key]: `${+year.start + 1}`,
      })
      setMonth({
        ...month,
        [key]: '1',
      })
      setDay({
        ...day,
        [key]: day.start,
      })
    } else if (checkMonth(+day.start, +month.start + 1, +year.start)) {
      setYear({
        ...year,
        [key]: year.start,
      })
      setMonth({
        ...month,
        [key]: `${+month.start + 1}`,
      })
      setDay({
        ...day,
        [key]: day.start,
      })
    } else if (+month.start === 1 && leap) {
      setYear({
        ...year,
        [key]: year.start,
      })
      setMonth({
        ...month,
        [key]: `${+month.start + 2}`,
      })
      setDay({
        ...day,
        [key]: `${+day.start - 29}`,
      })
    } else if (+month.start === 1 && !leap) {
      setYear({
        ...year,
        [key]: year.start,
      })
      setMonth({
        ...month,
        [key]: `${+month.start + 2}`,
      })
      setDay({
        ...day,
        [key]: `${+day.start - 28}`,
      })
    } else {
      setYear({
        ...year,
        [key]: year.start,
      })
      setDay({
        ...day,
        [key]: 1,
      })
      setMonth({
        ...month,
        [key]: `${+month.start + 2}`,
      })
    }

    setHour({
      ...hour,
      [key]: hour.start,
    })
    setMinute({
      ...minute,
      [key]: minute.start,
    })
    setSecond({
      ...second,
      [key]: second.start,
    })
  }

  function set3DaysBefore(key: string) {
    if (!checkMonth(+day.start - 3, +month.start, +year.start)) {
      setDay({
        ...day,
        [key]: '1',
      })
      if (+month.start !== 12) {
        setMonth({
          ...month,
          [key]: `${+month.start + 1}`,
        })
        setYear({
          ...year,
          [key]: `${+year.start}`,
        })
      } else {
        setMonth({
          ...month,
          [key]: '1',
        })
        setYear({
          ...year,
          [key]: `${+year.start + 1}`,
        })
      }
    } else {
      setYear({
        ...year,
        [key]: `${+year.start}`,
      })
      setMonth({
        ...month,
        [key]: `${+month.start}`,
      })
      setDay({
        ...day,
        [key]: `${+day.start - 3}`,
      })
    }

    setHour({
      ...hour,
      [key]: hour.start,
    })
    setMinute({
      ...minute,
      [key]: minute.start,
    })
    setSecond({
      ...second,
      [key]: second.start,
    })
  }

  function setWeekBefore(key: string) {
    if (!checkMonth(+day.start - 14, +month.start, +year.start)) {
      setDay({
        ...day,
        [key]: '1',
      })
      if (+month.start !== 12) {
        setMonth({
          ...month,
          [key]: `${+month.start + 1}`,
        })
        setYear({
          ...year,
          [key]: `${+year.start}`,
        })
      } else {
        setMonth({
          ...month,
          [key]: '1',
        })
        setYear({
          ...year,
          [key]: `${+year.start + 1}`,
        })
      }
    } else {
      setYear({
        ...year,
        [key]: `${+year.start}`,
      })
      setMonth({
        ...month,
        [key]: `${+month.start}`,
      })
      setDay({
        ...day,
        [key]: `${+day.start - 7}`,
      })
    }

    setHour({
      ...hour,
      [key]: hour.start,
    })
    setMinute({
      ...minute,
      [key]: minute.start,
    })
    setSecond({
      ...second,
      [key]: second.start,
    })
  }

  function set2WeeksBefore(key: string) {
    if (!checkMonth(+day.start - 14, +month.start, +year.start)) {
      setDay({
        ...day,
        [key]: '1',
      })
      if (+month.start !== 12) {
        setMonth({
          ...month,
          [key]: `${+month.start + 1}`,
        })
        setYear({
          ...year,
          [key]: `${+year.start}`,
        })
      } else {
        setMonth({
          ...month,
          [key]: '1',
        })
        setYear({
          ...year,
          [key]: `${+year.start + 1}`,
        })
      }
    } else {
      setYear({
        ...year,
        [key]: `${+year.start}`,
      })
      setMonth({
        ...month,
        [key]: `${+month.start}`,
      })
      setDay({
        ...day,
        [key]: `${+day.start - 14}`,
      })
    }

    setHour({
      ...hour,
      [key]: hour.start,
    })
    setMinute({
      ...minute,
      [key]: minute.start,
    })
    setSecond({
      ...second,
      [key]: second.start,
    })
  }

  function transformTime(time: string) {
    if (time.length === 1) {
      return `0${time}`
    }
    return time
  }

  function isStartTime() {
    return !!(year.start && month.start && day.start && hour.start && minute.start && second.start)
  }

  function isEnterTime() {
    return !!(year.enter && month.enter && day.enter && hour.enter && minute.enter && second.enter)
  }

  function isEndTime() {
    return !!(year.end && month.end && day.end && hour.end && minute.end && second.end)
  }

  function createStartTime() {
    const startTimestamp = +new Date(
      +year.start,
      +month.start - 1,
      +day.start,
      +hour.start,
      +minute.start,
      +second.start
    )

    if (startTimestamp.toString().length > 10) {
      return +startTimestamp.toString().slice(0, 10)
    } else {
      return startTimestamp
    }
  }

  function createEndTime() {
    const endTimestamp = +new Date(+year.end, +month.end - 1, +day.end, +hour.end, +minute.end, +second.end)

    if (endTimestamp.toString().length > 10) {
      return +endTimestamp.toString().slice(0, 10)
    } else {
      return endTimestamp
    }
  }

  function createEnterTime() {
    const enterTimestamp = +new Date(
      +year.enter,
      +month.enter - 1,
      +day.enter,
      +hour.enter,
      +minute.enter,
      +second.enter
    )

    if (enterTimestamp.toString().length > 10) {
      return +enterTimestamp.toString().slice(0, 10)
    } else {
      return enterTimestamp
    }
  }

  const [creating, setCreating] = useState(false)

  //Errors

  const isFutureStart = useMemo(() => {
    if (Object.values({ year, month, day, hour, minute, second }).some(({ start, end }) => !start || !end)) return true

    const start = +new Date(+year.start, +month.start - 1, +day.start, +hour.start, +minute.start, +second.start)

    return start > Date.now()
  }, [year, month, day, hour, minute, second])

  const isFutureEnd = useMemo(() => {
    if (Object.values({ year, month, day, hour, minute, second }).some(({ start, end }) => !start || !end)) return true

    const end = +new Date(+year.end, +month.end - 1, +day.end, +hour.end, +minute.end, +second.end)

    return end > Date.now()
  }, [year, month, day, hour, minute, second])

  const isLater = useMemo(() => {
    if (Object.values({ year, month, day, hour, minute, second }).some(({ start, end }) => !start || !end)) return

    const start = +new Date(+year.start, +month.start - 1, +day.start, +hour.start, +minute.start, +second.start)

    const end = +new Date(+year.end, +month.end - 1, +day.end, +hour.end, +minute.end, +second.end)

    return !(end > start && end !== start)
  }, [year, month, day, hour, minute, second])

  const [isTierFarming, toggleTierFarming] = useState(false)

  const isReady = useMemo(() => {
    if (isTierFarming) {
      return (
        baseCurrency &&
        currencyB &&
        tokenCurrency &&
        bonusRewardCurrency &&
        lockedCurrency &&
        reward &&
        bonusReward &&
        isStartTime() &&
        isEndTime() &&
        isEndTime() &&
        !isLater &&
        isFutureStart &&
        lowTierLimit &&
        mediumTierLimit &&
        highTierLimit &&
        lowTierMultiplier &&
        mediumTierMultiplier &&
        highTierMultiplier &&
        isFutureEnd
      )
    } else {
      return (
        baseCurrency &&
        currencyB &&
        tokenCurrency &&
        bonusRewardCurrency &&
        reward &&
        bonusReward &&
        isStartTime() &&
        isEndTime() &&
        isEndTime() &&
        !isLater &&
        isFutureStart &&
        isFutureEnd
      )
    }
  }, [
    baseCurrency,
    currencyB,
    tokenCurrency,
    bonusRewardCurrency,
    lockedCurrency,
    reward,
    bonusReward,
    isStartTime(),
    isEndTime(),
    isEnterTime(),
    isLater,
    isFutureStart,
    lowTierLimit,
    mediumTierLimit,
    highTierLimit,
    lowTierMultiplier,
    mediumTierMultiplier,
    highTierMultiplier,
    isFutureEnd,
    isTierFarming,
  ])

  return (
    <PageWrapper>
      <div style={{ display: 'flex', marginBottom: '1rem' }}>
        <div>
          <TYPE.black style={{ fontSize: ' 18px', marginBottom: '0.5rem' }}>Tier farming</TYPE.black>
          <Toggle
            checked={'Yes'}
            unchecked={'No'}
            isActive={isTierFarming}
            toggle={() => toggleTierFarming(!isTierFarming)}
          />
        </div>
        <div style={{ marginLeft: '3rem' }}>
          <TYPE.black style={{ fontSize: ' 18px', marginBottom: '0.5rem' }}>Minimal range (in ticks)</TYPE.black>
          <Input
                pattern="^\d*\.?\d*$"
                value={minimalRange}
                onChange={(e) => setMinimalRange(e.target.value)}
                placeholder="Enter minimum"
              />
        </div>
      </div>
      <MainContentWrapper>
        <PairWrapper>
          <PairToken>
            <div style={{ fontSize: '18px' }}>Pair</div>
            <PairSelectorWrapper>
              <CurrencyDropdown
                value={formattedAmounts[Field.CURRENCY_A]}
                onUserInput={onFieldAInput}
                hideInput={true}
                onCurrencySelect={handleCurrencyASelect}
                currency={currencies[Field.CURRENCY_A]}
                id="add-incentive-input-tokena"
                showCommonBases
                showBalance
              />
              <div style={{ width: '12px' }} />

              <CurrencyDropdown
                value={formattedAmounts[Field.CURRENCY_B]}
                onUserInput={onFieldBInput}
                hideInput={true}
                onCurrencySelect={handleCurrencyBSelect}
                currency={currencies[Field.CURRENCY_B]}
                id="add-incentive-input-tokena"
                showCommonBases
                showBalance
              />
            </PairSelectorWrapper>
          </PairToken>
          <RewardToken active={currencyIdA && currencyIdB}>
            <div style={{ fontSize: '18px' }}>Reward</div>
            <RewardInput>
              <Input
                pattern="^\d*\.?\d*$"
                disabled={!currencyIdA || !currencyIdB}
                value={reward}
                onChange={(e) => handleRewardInput(e.target.value)}
                placeholder="Enter an amount"
              />

              <CurrencyDropdown
                value={formattedAmounts[Field.REWARD_TOKEN]}
                onUserInput={onRewardTokenInput}
                hideInput={true}
                onCurrencySelect={currencyIdA && currencyIdB ? handleRewardTokenSelect : null}
                currency={currencies[Field.REWARD_TOKEN]}
                id="add-incentive-input-reward"
                showCommonBases
                showBalance
              />
            </RewardInput>
            {/* {errored(3, 6) && (
              <div
                style={{
                  fontSize: '13px',
                  color: '#de285b',
                  position: 'absolute',
                  marginTop: '8px',
                }}
              >
                {errorMessage}
              </div>
            )} */}
          </RewardToken>
        </PairWrapper>
        <div
          style={{
            marginTop: '20px',
            flex: 1,
            opacity: currencyIdA && currencyIdB && reward && rewardTokenId ? '1' : '0.4',
          }}
        >
          <div style={{ fontSize: '20px' }}>
            <TYPE.black>Bonus Reward</TYPE.black>
          </div>
          <div style={{ display: 'flex', marginTop: '1rem' }}>
            <Input
              pattern="^\d*\.?\d*$"
              disabled={!currencyIdA || !currencyIdB || !reward}
              value={bonusReward}
              onChange={(e) => handleBonusRewardInput(e.target.value)}
              placeholder="Enter an amount"
            />
            <CurrencyDropdown
              value={formattedAmounts[Field.BONUS_REWARD_TOKEN]}
              onUserInput={onBonusRewardTokenInput}
              hideInput={true}
              onCurrencySelect={currencyIdA && currencyIdB ? handleBonusRewardTokenSelect : null}
              currency={currencies[Field.BONUS_REWARD_TOKEN]}
              id="add-incentive-input-bonus-reward"
              showCommonBases
              showBalance
            />
          </div>
        </div>
        <div style={{ marginTop: '2rem', width: '100%' }}>
          <div
            style={{
              paddingTop: '2rem',
              borderTop: '1px solid #24214A',
              opacity: currencyIdA && currencyIdB && reward && rewardTokenId ? '1' : '0.4',
            }}
          >
            <div style={{ fontSize: '20px' }}>
              <TYPE.black>
                <Trans>Incentive time</Trans>
              </TYPE.black>
            </div>
            <TimeWrapper>
              <TimeWrapperPart>
                <TimeWrapperPartButtons>
                  <TYPE.black>
                    <Trans>Enter time</Trans>
                  </TYPE.black>
                  <TimeWrapperControls>
                    <button
                      style={{
                        border: 'none',
                        marginRight: '10px',
                        fontSize: '15px',
                        background: 'transparent',
                        color: isStartTime() ? '#4761ec' : '#232c59',
                      }}
                      onClick={() => set2WeeksBefore('enter')}
                    >
                      <Trans>-2 weeks</Trans>
                    </button>
                    <button
                      style={{
                        border: 'none',
                        marginRight: '10px',
                        fontSize: '15px',
                        background: 'transparent',
                        color: isStartTime() ? '#4761ec' : '#232c59',
                      }}
                      onClick={() => setWeekBefore('enter')}
                    >
                      <Trans>-7 days</Trans>
                    </button>
                    <button
                      style={{
                        border: 'none',
                        marginRight: '10px',
                        fontSize: '15px',
                        background: 'transparent',
                        color: isStartTime() ? '#4761ec' : '#232c59',
                      }}
                      onClick={() => set3DaysBefore('enter')}
                    >
                      <Trans>-3 days</Trans>
                    </button>
                    <button
                      disabled={!isStartTime()}
                      style={{
                        border: 'none',
                        fontSize: '15px',
                        background: 'transparent',
                        color: isStartTime() ? '#4761ec' : '#232c59',
                      }}
                      onClick={() => setStart('enter')}
                    >
                      <Trans>Start time</Trans>
                    </button>
                  </TimeWrapperControls>
                </TimeWrapperPartButtons>
                <TimeInputsWrapper error={isLater || !isFutureEnd}>
                  <TimeInputsWrapperInner>
                    <TimeInputTitle>Date</TimeInputTitle>
                    <div style={{ display: 'flex' }}>
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="[0-9]+"
                        placeholder="YYYY"
                        id="year-enter"
                        maxLength="4"
                        onChange={(e) => handleDateSelect(e, 'enter')}
                        value={year.enter}
                        style={{ width: '76px' }}
                      />
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="^(0?[1-9]|1[012])$"
                        placeholder="MM"
                        id="month-enter"
                        maxLength="2"
                        value={month.enter}
                        onChange={(e) => handleDateSelect(e, 'enter')}
                        style={{ width: '60px' }}
                      />
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="(0[1-9]|[12]\d|3[01])"
                        placeholder="DD"
                        id="day-enter"
                        maxLength="2"
                        value={day.enter}
                        onChange={(e) => handleDateSelect(e, 'enter')}
                        style={{ width: '60px' }}
                      />
                    </div>
                  </TimeInputsWrapperInner>

                  <div>
                    <TimeInputTitle>Time</TimeInputTitle>
                    <div style={{ display: 'flex' }}>
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="^(?:([01]?\d|2[0-3]))$"
                        placeholder="hh"
                        id="hour-enter"
                        maxLength="2"
                        value={hour.enter}
                        onChange={(e) => handleDateSelect(e, 'enter')}
                        style={{ width: '62px' }}
                      />
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="^(?:([0-5]?\d))$"
                        placeholder="mm"
                        id="minute-enter"
                        maxLength="2"
                        value={minute.enter}
                        onChange={(e) => handleDateSelect(e, 'enter')}
                        style={{ width: '62px' }}
                      />
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="^(?:([0-5]?\d))$"
                        placeholder="ss"
                        id="second-enter"
                        maxLength="2"
                        value={second.enter}
                        onChange={(e) => handleDateSelect(e, 'enter')}
                        style={{ width: '50px', display: 'none' }}
                      />
                    </div>
                  </div>
                </TimeInputsWrapper>
              </TimeWrapperPart>
              <TimeWrapperPart>
                <TimeWrapperPartButtons>
                  <TYPE.black>
                    <Trans>Start time</Trans>
                  </TYPE.black>
                  <TimeWrapperControls>
                    <button
                      disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                      style={{
                        border: 'none',
                        fontSize: '15px',
                        background: 'transparent',
                        color: '#4761ec',
                      }}
                      onClick={() => setNow('start', 30)}
                    >
                      <Trans>+30 min</Trans>
                    </button>
                    <button
                      disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                      style={{
                        border: 'none',
                        fontSize: '15px',
                        background: 'transparent',
                        color: '#4761ec',
                      }}
                      onClick={() => setNow('start', 10)}
                    >
                      <Trans>+10 min</Trans>
                    </button>
                    <button
                      disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                      style={{
                        border: 'none',
                        fontSize: '15px',
                        background: 'transparent',
                        color: '#4761ec',
                      }}
                      onClick={() => setNow('start', 5)}
                    >
                      <Trans>+5 min</Trans>
                    </button>
                  </TimeWrapperControls>
                </TimeWrapperPartButtons>
                <TimeInputsWrapper error={isLater || !isFutureStart}>
                  <TimeInputsWrapperInner>
                    <TimeInputTitle>Date</TimeInputTitle>
                    <div style={{ display: 'flex' }}>
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="[0-9]+"
                        placeholder="YYYY"
                        id="year-start"
                        maxLength="4"
                        onChange={(e) => handleDateSelect(e, 'start')}
                        value={year.start}
                        style={{ width: '76px' }}
                      />
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="^(0?[1-9]|1[012])$"
                        placeholder="MM"
                        id="month-start"
                        maxLength="2"
                        value={month.start}
                        onChange={(e) => handleDateSelect(e, 'start')}
                        style={{ width: '60px' }}
                      />
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="(0[1-9]|[12]\d|3[01])"
                        placeholder="DD"
                        id="day-start"
                        maxLength="2"
                        value={day.start}
                        onChange={(e) => handleDateSelect(e, 'start')}
                        style={{ width: '60px' }}
                      />
                    </div>
                  </TimeInputsWrapperInner>

                  <div>
                    <TimeInputTitle>Time</TimeInputTitle>
                    <div style={{ display: 'flex' }}>
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="^(?:([01]?\d|2[0-3]))$"
                        placeholder="hh"
                        id="hour-start"
                        maxLength="2"
                        value={hour.start}
                        onChange={(e) => handleDateSelect(e, 'start')}
                        style={{ width: '62px' }}
                      />
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="^(?:([0-5]?\d))$"
                        placeholder="mm"
                        id="minute-start"
                        maxLength="2"
                        value={minute.start}
                        onChange={(e) => handleDateSelect(e, 'start')}
                        style={{ width: '62px' }}
                      />
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="^(?:([0-5]?\d))$"
                        placeholder="ss"
                        id="second-start"
                        maxLength="2"
                        value={second.start}
                        onChange={(e) => handleDateSelect(e, 'start')}
                        style={{ width: '62px', display: 'none' }}
                      />
                    </div>
                  </div>
                </TimeInputsWrapper>
              </TimeWrapperPart>
              <TimeWrapperPart>
                <TimeWrapperPartButtons>
                  <TYPE.black>
                    <Trans>End time</Trans>
                  </TYPE.black>
                  <TimeWrapperControls>
                    <button
                      disabled={!isStartTime()}
                      style={{
                        border: 'none',
                        marginRight: '10px',
                        fontSize: '15px',
                        background: 'transparent',
                        color: isStartTime() ? '#4761ec' : '#232c59',
                      }}
                      onClick={() => setMonthAfter('end')}
                    >
                      <Trans>+1 month</Trans>
                    </button>
                    <button
                      disabled={!isStartTime()}
                      style={{
                        border: 'none',
                        marginRight: '10px',
                        fontSize: '15px',
                        background: 'transparent',
                        color: isStartTime() ? '#4761ec' : '#232c59',
                      }}
                      onClick={() => setWeekAfter('end')}
                    >
                      <Trans>+1 week</Trans>
                    </button>
                    <button
                      disabled={!isStartTime()}
                      style={{
                        border: 'none',
                        marginRight: '10px',
                        fontSize: '15px',
                        background: 'transparent',
                        color: isStartTime() ? '#4761ec' : '#232c59',
                      }}
                      onClick={() => setDayAfter('end')}
                    >
                      <Trans>+1 day</Trans>
                    </button>
                    <button
                      disabled={!isStartTime()}
                      style={{
                        border: 'none',
                        fontSize: '15px',
                        background: 'transparent',
                        color: isStartTime() ? '#4761ec' : '#232c59',
                      }}
                      onClick={() => setStart('end')}
                    >
                      <Trans>Start time</Trans>
                    </button>
                  </TimeWrapperControls>
                </TimeWrapperPartButtons>
                <TimeInputsWrapper error={isLater || !isFutureEnd}>
                  <TimeInputsWrapperInner>
                    <TimeInputTitle>Date</TimeInputTitle>
                    <div style={{ display: 'flex' }}>
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="[0-9]+"
                        placeholder="YYYY"
                        id="year-end"
                        maxLength="4"
                        onChange={(e) => handleDateSelect(e, 'end')}
                        value={year.end}
                        style={{ width: '76px' }}
                      />
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="^(0?[1-9]|1[012])$"
                        placeholder="MM"
                        id="month-end"
                        maxLength="2"
                        value={month.end}
                        onChange={(e) => handleDateSelect(e, 'end')}
                        style={{ width: '60px' }}
                      />
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="(0[1-9]|[12]\d|3[01])"
                        placeholder="DD"
                        id="day-end"
                        maxLength="2"
                        value={day.end}
                        onChange={(e) => handleDateSelect(e, 'end')}
                        style={{ width: '60px' }}
                      />
                    </div>
                  </TimeInputsWrapperInner>

                  <div>
                    <TimeInputTitle>Time</TimeInputTitle>
                    <div style={{ display: 'flex' }}>
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="^(?:([01]?\d|2[0-3]))$"
                        placeholder="hh"
                        id="hour-end"
                        maxLength="2"
                        value={hour.end}
                        onChange={(e) => handleDateSelect(e, 'end')}
                        style={{ width: '62px' }}
                      />
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="^(?:([0-5]?\d))$"
                        placeholder="mm"
                        id="minute-end"
                        maxLength="2"
                        value={minute.end}
                        onChange={(e) => handleDateSelect(e, 'end')}
                        style={{ width: '62px' }}
                      />
                      <TimeInput
                        disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                        pattern="^(?:([0-5]?\d))$"
                        placeholder="ss"
                        id="second-end"
                        maxLength="2"
                        value={second.end}
                        onChange={(e) => handleDateSelect(e, 'end')}
                        style={{ width: '50px', display: 'none' }}
                      />
                    </div>
                  </div>
                </TimeInputsWrapper>
              </TimeWrapperPart>
            </TimeWrapper>
          </div>

          {isTierFarming && (
            <>
              <div
                style={{
                  paddingTop: '2rem',
                  borderTop: '1px solid #24214A',
                  opacity: currencyIdA && currencyIdB && reward && rewardTokenId ? '1' : '0.4',
                }}
              >
                <div style={{ fontSize: '20px' }}>
                  <TYPE.black>
                    <Trans>Farming tiers</Trans>
                  </TYPE.black>
                </div>
                <TimeWrapper>
                  <TimeWrapperPart>
                    <TimeWrapperPartButtons>
                      <TYPE.black>
                        <Trans>Tier limits (in tokens)</Trans>
                      </TYPE.black>
                    </TimeWrapperPartButtons>
                    <TimeInputsWrapper error={isLater || !isFutureStart}>
                      <TimeInputsWrapperInner>
                        <div style={{ display: 'flex' }}>
                          <TimeInput
                            disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                            placeholder="Low tier"
                            id="low-tier-limit"
                            onChange={(e) => setLowTierLimit(e.target.value)}
                            value={lowTierLimit}
                          />
                          <TimeInput
                            disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                            placeholder="Mid tier"
                            id="mid-tier-limit"
                            value={mediumTierLimit}
                            onChange={(e) => setMediumTierLimit(e.target.value)}
                          />
                          <TimeInput
                            disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                            placeholder="High tier"
                            id="high-tier-limit"
                            value={highTierLimit}
                            onChange={(e) => setHighTierLimit(e.target.value)}
                          />
                        </div>
                      </TimeInputsWrapperInner>
                    </TimeInputsWrapper>
                  </TimeWrapperPart>
                  <TimeWrapperPart>
                    <TimeWrapperPartButtons>
                      <TYPE.black>
                        <Trans>Tier multipliers (in %)</Trans>
                      </TYPE.black>
                    </TimeWrapperPartButtons>
                    <TimeInputsWrapper error={isLater || !isFutureEnd}>
                      <TimeInputsWrapperInner>
                        <div style={{ display: 'flex' }}>
                          <TimeInput
                            disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                            placeholder="Low tier"
                            id="low-tier-multiplier"
                            onChange={(e) => setLowTierMultiplier(e.target.value)}
                            value={lowTierMultiplier}
                          />
                          <TimeInput
                            disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                            placeholder="Mid tier"
                            id="mid-tier-multiplier"
                            value={mediumTierMultiplier}
                            onChange={(e) => setMediumTierMultiplier(() => e.target.value)}
                          />
                          <TimeInput
                            disabled={!currencyIdA || !currencyIdB || !reward || !rewardTokenId}
                            placeholder="High tier"
                            id="high-tier-multiplier"
                            value={highTierMultiplier}
                            onChange={(e) => {
                              const temp = e.target.value
                              e.target.value = ''
                              setHighTierMultiplier(temp)
                              e.target.value = temp
                              setHighTierMultiplier(temp)
                            }}
                          />
                        </div>
                      </TimeInputsWrapperInner>
                    </TimeInputsWrapper>
                  </TimeWrapperPart>
                </TimeWrapper>
              </div>
              <div
                style={{
                  marginTop: '1rem',
                  opacity: currencyIdA && currencyIdB && reward && rewardTokenId ? '1' : '0.4',
                }}
              >
                <TimeWrapperPart>
                  <TimeWrapperPartButtons>
                    <TYPE.black>
                      <Trans>Locked Token</Trans>
                    </TYPE.black>
                  </TimeWrapperPartButtons>
                  <TimeInputsWrapper>
                    <CurrencyDropdown
                      value={0}
                      onUserInput={(e) => console.log(e)}
                      hideInput={true}
                      onCurrencySelect={currencyIdA && currencyIdB ? handleLockedTokenSelect : null}
                      currency={lockedCurrency}
                      id="add-incentive-input-locked-token"
                      showCommonBases
                      showBalance
                    />
                  </TimeInputsWrapper>
                </TimeWrapperPart>
                <TimeWrapperPart></TimeWrapperPart>
              </div>
            </>
          )}
          <div style={{ marginTop: '1rem' }}>
            <SummaryWrapper>
              <SummaryPool>
                {baseCurrency && currencyB && reward && tokenCurrency ? (
                  <TokenLogo
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 600,
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      background: stringToColour(tokenCurrency.symbol).background,
                      color: stringToColour(tokenCurrency.symbol).text,
                      border: `2px solid ${stringToColour(tokenCurrency.symbol).border}`,
                    }}
                  >
                    {tokenCurrency.symbol.slice(0, 2)}
                  </TokenLogo>
                ) : (
                  <div
                    style={{
                      backgroundColor: '#373d4e',
                      width: '35px',
                      height: '35px',
                      borderRadius: '50%',
                      marginRight: '1rem',
                    }}
                  ></div>
                )}
                <div>
                  {baseCurrency && currencyB && reward && tokenCurrency ? (
                    <div title={reward}>{`${reward.length > 5 ? reward.slice(0, 3) + '..' : reward} ${
                      tokenCurrency?.symbol
                    }`}</div>
                  ) : (
                    <div
                      style={{
                        height: '19.2px',
                        width: '80px',
                        backgroundColor: '#373d4e',
                        borderRadius: '6px',
                      }}
                    ></div>
                  )}
                  {baseCurrency && currencyB && reward && tokenCurrency ? (
                    <div style={{ fontSize: '12px', color: '#909090' }}>
                      for{' '}
                      <span
                        style={{ color: '#e81082', boxShadow: 'inset 0 -1px currentColor' }}
                      >{`${baseCurrency?.symbol}/${currencyB?.symbol}`}</span>
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: '#373d4e',
                        borderRadius: '6px',
                        height: '15.2px',
                        width: '65px',
                        marginTop: '4px',
                      }}
                    ></div>
                  )}
                </div>
              </SummaryPool>
              <SummaryDate>
                <div style={{ textTransform: 'uppercase', fontSize: '13px', color: '#909090' }}>From</div>
                {year.start && month.start && day.start && hour.start && minute.start && second.start ? (
                  <div style={{ fontSize: '16px' }}>{`${transformTime(day.start)}.${transformTime(
                    month.start
                  )}.${year.start.slice(2, year.start.length)} — ${transformTime(hour.start)}:${transformTime(
                    minute.start
                  )}`}</div>
                ) : (
                  <div
                    style={{
                      fontSize: '16px',
                      height: '19.2px',
                      width: '150px',
                      backgroundColor: '#373d4e',
                      borderRadius: '6px',
                      marginTop: '4px',
                    }}
                  ></div>
                )}
              </SummaryDate>
              <SummaryDate>
                <div style={{ textTransform: 'uppercase', fontSize: '13px', color: '#909090' }}>Till</div>
                {year.end && month.end && day.end && hour.end && minute.end && second.end ? (
                  <div style={{ fontSize: '16px' }}>{`${transformTime(day.end)}.${transformTime(
                    month.end
                  )}.${year.end.slice(2, year.end.length)} — ${transformTime(hour.end)}:${transformTime(
                    minute.end
                  )}`}</div>
                ) : (
                  <div
                    style={{
                      fontSize: '16px',
                      height: '19.2px',
                      width: '150px',
                      backgroundColor: '#373d4e',
                      borderRadius: '6px',
                      marginTop: '4px',
                    }}
                  ></div>
                )}
              </SummaryDate>
              <div style={{ width: '100%' }}>
                {!account ? (
                  <button
                    onClick={toggleWalletModal}
                    style={{
                      width: '100%',
                      height: '2.8rem',
                      background: '#601fb3',
                      color: 'white',
                      border: 'none',
                      borderRadius: '10px',
                      fontSize: '16px',
                      fontFamily: 'Inter',
                    }}
                  >
                    Connect Wallet
                  </button>
                ) : showRewardApproval ? (
                  <div>
                    {approvalReward === ApprovalState.PENDING ? (
                      <button
                        style={{
                          width: '100%',
                          height: '2.8rem',
                          background: '#601fb3',
                          cursor: 'pointer',
                          color: 'white',
                          border: 'none',
                          borderRadius: '10px',
                          fontSize: '16px',
                          fontFamily: 'Inter',
                        }}
                      >
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Loader
                            style={{
                              display: 'block',
                              marginRight: '10px',
                            }}
                            stroke={'white'}
                          />
                          {`Approving ${tokenCurrency?.symbol}`}
                        </span>
                      </button>
                    ) : (
                      <button
                        style={{
                          width: '100%',
                          height: '2.8rem',
                          background: '#601fb3',
                          cursor: 'pointer',
                          color: 'white',
                          border: 'none',
                          borderRadius: '10px',
                          fontSize: '16px',
                          fontFamily: 'Inter',
                        }}
                        onClick={approveRewardCallback}
                      >
                        {`Approve ${tokenCurrency?.symbol}`}
                      </button>
                    )}
                  </div>
                ) : showBonusRewardApproval ? (
                  <div>
                    {approvalReward === ApprovalState.PENDING ? (
                      <button
                        style={{
                          width: '100%',
                          height: '2.8rem',
                          background: '#601fb3',
                          cursor: 'pointer',
                          color: 'white',
                          border: 'none',
                          borderRadius: '10px',
                          fontSize: '16px',
                          fontFamily: 'Inter',
                        }}
                      >
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Loader
                            style={{
                              display: 'block',
                              marginRight: '10px',
                            }}
                            stroke={'white'}
                          />
                          {`Approving ${bonusRewardCurrency?.symbol}`}
                        </span>
                      </button>
                    ) : (
                      <button
                        style={{
                          width: '100%',
                          height: '2.8rem',
                          background: '#601fb3',
                          cursor: 'pointer',
                          color: 'white',
                          border: 'none',
                          borderRadius: '10px',
                          fontSize: '16px',
                          fontFamily: 'Inter',
                        }}
                        onClick={approveBonusRewardCallback}
                      >
                        {`Approve ${bonusRewardCurrency?.symbol}`}
                      </button>
                    )}
                  </div>
                ) : isReady ? (
                  <CreateIncentive
                    currencyA={baseCurrency}
                    currencyB={currencyB}
                    tokenRewardAddress={tokenCurrency}
                    bonusRewardAddress={bonusRewardCurrency}
                    reward={reward}
                    bonusReward={bonusReward}
                    lockedToken={lockedCurrency}
                    low={lowTierLimit}
                    tierLimits={{
                      low: lowTierLimit,
                      medium: mediumTierLimit,
                      high: highTierLimit,
                    }}
                    tierMultipliers={{
                      low: lowTierMultiplier,
                      medium: mediumTierMultiplier,
                      high: highTierMultiplier,
                    }}
                    startTime={createStartTime()}
                    endTime={createEndTime()}
                    toggleDisabled={() => setCreating((old) => !old)}
                    enterStartTime={createEnterTime()}
                    isTierFarming={isTierFarming}
                    minimalRange={minimalRange}
                  />
                ) : (
                  <button
                    // onClick={() => createIncentive()}
                    style={{
                      width: '100%',
                      height: '2.8rem',
                      background: '#261141',
                      color: '#412467',
                      border: 'none',
                      borderRadius: '10px',
                      fontSize: '16px',
                      fontFamily: 'Inter',
                    }}
                  >
                    <Trans>Create incentive</Trans>
                  </button>
                )}
              </div>
            </SummaryWrapper>
          </div>
        </div>
      </MainContentWrapper>
      {isLater && (
        <ErrorWrapper>
          <span>Start time must be before end time</span>
        </ErrorWrapper>
      )}
      {!isFutureStart && (
        <ErrorWrapper>
          <span>Start time must be in future</span>
        </ErrorWrapper>
      )}
      {!isFutureEnd && (
        <ErrorWrapper>
          <span>End time must be in future</span>
        </ErrorWrapper>
      )}
    </PageWrapper>
  )
}
